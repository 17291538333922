<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Edit My Details</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    <router-link :to="{name: 'my-details'}">My Details</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Edit</li>
            </ol>
        </nav>
        <b-container fluid v-model="page_loader">
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div v-if="employee">
                <form>
                    <h4 class="mb-3">Personal Details</h4>
                    <hr>
                    <div class="col-md-8">
                        <div class="form-group row">
                            <label class="col-md-3">
                                Full Name
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <input
                                    type="text"
                                    class="form-control"
                                    aria-describedby="fullnameHelpInline"
                                    v-model="employee.full_name"
                                    @change="textTitleCase($event, 'full_name')"
                                    autocomplete="off"
                                />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Date Of Birth
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <b-input-group size="md" class="mb-1em">
                                    <date-pick
                                        v-model="employee.dob"
                                        :isDateDisabled="isFutureDate"
                                        :selectableYearRange ="dob_selectable_years"
                                        :displayFormat="'DD-MM-YYYY'"
                                    ></date-pick>
                                </b-input-group>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Gender
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <b-form-group>
                                    <b-form-radio-group
                                        id="radio-group-1"
                                        v-model="employee.gender"
                                        :options="genders"
                                        name="radio-options"
                                    ></b-form-radio-group>
                                </b-form-group>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Father Name
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <input
                                    type="text"
                                    class="form-control"
                                    aria-describedby="fathernameHelpInline"
                                    v-model="employee.father_name"
                                    @change="textTitleCase($event, 'father_name')"
                                    autocomplete="off"
                                />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Mother Name
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <input
                                    type="text"
                                    class="form-control"
                                    aria-describedby="mothernameHelpInline"
                                    v-model="employee.mother_name"
                                    @change="textTitleCase($event, 'mother_name')"
                                    autocomplete="off"
                                />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Marital Status
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <b-form-group >
                                    <b-form-radio-group
                                        id="radio-slots-3"
                                        v-model="employee.marital_status"
                                        :options="marital_status_options"
                                        name="radio-options-slots-3"
                                    ></b-form-radio-group>
                                </b-form-group>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Mobile Number
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <input
                                    type="tel"
                                    class="form-control"
                                    aria-describedby="phone1HelpInline"
                                    v-model="employee.mobile_number"
                                    autocomplete="off"
                                />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Phone Number
                            </label>
                            <div class="col-md-9">
                                <input
                                    type="tel"
                                    class="form-control"
                                    aria-describedby="phone2HelpInline"
                                    v-model="employee.phone_number"
                                    autocomplete="off"
                                />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Current Address
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <textarea
                                    class="form-control"
                                    id="current_addressFormControlTextarea1"
                                    v-model="employee.current_address"
                                    autocomplete="off"
                                    rows="3"
                                ></textarea>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Permanent Address
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <textarea
                                    class="form-control"
                                    id="permanent_addressFormControlTextarea1"
                                    v-model="employee.permanent_address"
                                    autocomplete="off"
                                    rows="3"
                                ></textarea>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Personal Email
                                <span class="text-danger" style="font-weight:bold;">*</span> 
                            </label>
                            <div class="col-md-9">
                                <input
                                    type="text"
                                    class="form-control"
                                    aria-describedby="emailHelpInline"
                                    v-model="employee.personal_email"
                                    autocomplete="off"
                                />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Aadhaar Number
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <input
                                    type="text"
                                    class="form-control"
                                    aria-describedby="adhaarnumberHelpInline"
                                    v-model="employee.adhaar_number"
                                    autocomplete="off"
                                />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                PAN Number
                            </label>
                            <div class="col-md-9">
                                <input
                                    type="text"
                                    class="form-control"
                                    aria-describedby="pancardberHelpInline"
                                    v-model="employee.pancard_number"
                                    autocomplete="off"
                                />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                UAN Number
                            </label>
                            <div class="col-md-9">
                                <input
                                    type="text"
                                    class="form-control"
                                    aria-describedby="uanHelpInline"
                                    v-model="employee.uan"
                                    autocomplete="off"
                                />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                ESIC Number
                            </label>
                            <div class="col-md-9">
                                <input
                                    type="text"
                                    class="form-control"
                                    aria-describedby="esicHelpInline"
                                    v-model="employee.esic"
                                    autocomplete="off"
                                />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Passport Number
                            </label>
                            <div class="col-md-9">
                                <input
                                    type="text"
                                    class="form-control"
                                    aria-describedby="passportberHelpInline"
                                    v-model="employee.passport_number"
                                    autocomplete="off"
                                />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Blood Group
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>

                            <div class="col-md-9">
                                <b-form-select
                                    value-field="item"
                                    text-field="name"
                                    v-model="employee.blood_group"
                                    :options="blood_group_options"
                                ></b-form-select>
                            </div>
                        </div>

                        <h4 class="mb-3">Bank Details</h4>
                        <hr>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Bank Name
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>

                            <div class="col-md-9">
                                <b-form-select
                                    value-field="item"
                                    text-field="name"
                                    v-model="employee.bank_name"
                                    :options="bank_name_options"
                                ></b-form-select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Bank Account Number
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <input
                                    type="number"
                                    class="form-control"
                                    aria-describedby="bankaccountnumberberHelpInline"
                                    v-model="employee.bank_account_number"
                                    autocomplete="off"
                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                IFSC Code
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <input
                                    type="text"
                                    class="form-control"
                                    aria-describedby="ifsccodeberHelpInline"
                                    v-model="employee.ifsc_code"
                                    @change="textUppercase"
                                    autocomplete="off"
                                />
                            </div>
                        </div>

                        <div class="form-group row" style="float:right !important;">
                            <div class="offset-xs-3 col-xs-9">
                                <button
                                    type="button"
                                    class="btn btn-primary text-right ma-10px"
                                    @click="info('UPDATE')"
                                >Update</button>
                            </div>
                            <div class="offset-xs-3 col-xs-9">
                                <button
                                    type="button"
                                    @click="$router.go(-1)"
                                    class="btn btn-secondary ma-10px"
                                >Cancel</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div v-else class="text-center">
                <span>
                    <font-awesome-icon icon="spinner" spin size="2x"></font-awesome-icon>
                </span>
            </div>
        </b-container>

        <b-modal
            :id="infoModal.id"
            :title="infoModal.title"
            centered
            size="md"
            @hide="resetInfoModal"
            no-close-on-backdrop
            no-close-on-esc
        >
            <p v-html="message" class="float-left"></p>
            <div slot="modal-footer" class="w-100" >
                <b-button class="mt-3 float-right" @click="cancel()">No</b-button>
                <b-button
                    v-if="infoModal.status == 'UPDATE'"
                    class="mt-3 float-right btn btn-primary"
                    variant="danger"
                    style="margin-right:10px;"
                    @click="updateEmployee()"
                >Yes</b-button>
            </div>

        </b-modal>

    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";
export default {
    components: {
        NotFound,
        DatePick
    },
    data() {
        return {
            dob_selectable_years: {
                from : null,
                to: null
            },
            access: false,
            api_error:"",
            employee:null,
            copy_of_employee: null,
            page_loader: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            genders: [
                { value: "female", text: "Female" },
                { value: "male", text: "Male" },
                { value: "others", text: "Others" }
            ],
            marital_status_options: [
                { value: "unmarried", text: "Unmarried" },
                { value: "married", text: "Married" }
            ],
            last_working_day:"",
            infoModal: {
                id: "modal-sm",
                title: "",
                content: "",
                status:""
            },
            bank_name_options: [{ item: "", name: "Select Bank Name" }],
            blood_group_options: [
                {item: null, name: "Select Blood Group"},
                {item: "A+", name: "A+"},
                {item: "A-", name: "A-"},
                {item: "B+", name: "B+"},
                {item: "B-", name: "B-"},
                {item: "O+", name: "O+"},
                {item: "O-", name: "O-"},
                {item: "AB+", name: "AB+"},
                {item: "AB-", name: "AB-"}
            ],
            message:"",
            status: "",
            emp_status:"",
            dob_previous_years_diff: 60,
            bankName:""
        }
    },
    mounted() {
        this.access = this.hasPermission("CAN_UPDATE_MY_DETAILS");
        let current_date = new Date;
        this.dob_selectable_years.to = current_date.getFullYear();
        this.dob_selectable_years.from =current_date.getFullYear() -this.dob_previous_years_diff;
        this.bankName =this.$store.getters.getAppSetting("ies_portal_user_interface", "bank_name_options");
        this.getEmployee();
        this.banksList();
        
    },
    methods: {
        isFutureDate(date) {
            const currentDate = new Date();
            return date > currentDate;
        },

        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        textTitleCase(event, key) {
            this.employee[key] = this.strTitleCase(event.target.value);
        },

        textUppercase (event) {
            this.BankIFSCError ="";
            this.employee.ifsc_code = event.target.value.toUpperCase();
        },

        info(status) {
            let errorCheck = 0;
            this.bankName.forEach(bank_details => {
                if(bank_details.name == this.employee.bank_name){
                    if(bank_details.length != null && bank_details.length != "" && bank_details.length != this.employee.bank_account_number.length) {
                        this.api_error = "Bank account number should be " + bank_details.length + " characters";
                        this.showAlert();
                        window.scrollTo(0, 0);
                        errorCheck = 1;
                    }
                    else if(bank_details.ifsc_start_with != null && bank_details.ifsc_start_with != "" && bank_details.ifsc_start_with != this.employee.ifsc_code.slice(0,4)) {
                        this.api_error = "IFSC code should be started with " + bank_details.ifsc_start_with;
                        this.showAlert();
                        window.scrollTo(0, 0);
                        errorCheck = 1;
                    }
                }
            });

            if( status == "UPDATE" && errorCheck == 0) {
                this.infoModal.title = `Submit Confirmation`;
                this.message = 'Are you sure you want Update' ;
                this.infoModal.status = status
                this.$root.$emit("bv::show::modal", this.infoModal.id, status);
            }
        },
        
        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },
        
        banksList: function(){
            
            if ((!this.bankName) || (this.bankName.length == 0)){
                this.api_error = "Unable to get bank name options from app settings";
                this.showAlert();
                return;
            }
            this.bankName.forEach(bank_name => {
                    this.bank_name_options.push({
                            item: bank_name.name,
                            name: bank_name.name
                        });
                    }
            )
        },

        getEmployee: function() {
            this.startProgressBar();
            this.page_loader = true;
            var query = this.getAuthHeaders();
            query['params'] = {};
            this.axios.get(this.$api.get_my_details, query).then(response => {
                const result = response.data.data;
                this.employee = result;
                this.copy_of_employee = JSON.parse(JSON.stringify(result));
                if (this.employee.marital_status == null){
                    this.employee.marital_status ="Unmarried";
                }
                this.updateProgressBar(true);
                this.page_loader = false;
            })
            .catch(err => {
                console.log(err);
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
                this.page_loader = false;
                this.showAlert();
            });
        },

        updateEmployee: function() {
            this.startProgressBar();
            this.employee['history'] = this.prepareHistory(this.employee);
            const data = this.deleteEmptyKeys(this.employee);
            this.cancel();
            let query = this.getAuthHeaders();
            query['params'] = {};
            this.axios.post(this.$api.update_my_details, data, query).then(() => {
                this.updateProgressBar(true);
                this.$router.push({ name: "my-details" });
            })
            .catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.employee.week_off = this.week_off
                this.updateProgressBar(false);
                this.showAlert();
                window.scrollTo(0, 0);
            });
        },

        prepareHistory: function(employee) {
            let history = {};
            let ignore_keys = ['employee_group', 'holiday_group', 'leave_group', 'user', 'history', 'reporting_to'];
            Object.keys(employee).forEach(key => {
                if (!ignore_keys.includes(key)) {
                    if ((this.copy_of_employee[key]!=employee[key])) {
                        history[key] = employee[key];
                    }
                }
            });
            return history;
        },

        cancel() {
            this.$root.$emit("bv::hide::modal", this.infoModal.id);
            this.employee.status = this.emp_status;
            this.status = this.employee.status;
        },
    }
}
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";

.vdpComponent > input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}
.vdpComponent > input {
    padding: 0.375rem 0.75rem;
    height: calc(1.5em + 0.75rem + 2px);
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    width: 100%;
}
.vdpClearInput {
    display: none;
}
.vdpComponent.vdpWithInput > input {
    padding-right: 0px;
}
.vdpOuterWrap.vdpFloating {
    z-index: 1000;
}


.badge-red-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>
